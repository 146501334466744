import $ from 'jquery'

export default $(function() {
	$(".menu").click(
		function() {
			$(".menu_box").stop(true, false).delay(0).animate({
				width: "100%",
				height: "100%"
			}, 0);
			$(".menu_box").find(".menu_mark").stop(true, false).delay(0).animate({
				opacity: "0.9"
			}, 500);
			$(".menu_box").find(".btn_closed_box").stop(true, false).delay(0).animate({
				opacity: "1"
			}, 500);
			$(".menu_box").find(".menu_nav").stop(true, false).delay(0).animate({
				right: "0"
			}, 500);
		}
	)
	$(".btn_closed").click(
		function() {
			$(".menu_box").stop(true, false).delay(500).animate({
				width: "0",
				height: "0"
			}, 0);
			$(".menu_box").find(".menu_mark").stop(true, false).delay(0).animate({
				opacity: "0"
			}, 500);
			$(".menu_box").find(".btn_closed_box").stop(true, false).delay(0).animate({
				opacity: "0"
			}, 500);
			$(".menu_box").find(".menu_nav").stop(true, false).delay(0).animate({
				right: "-80%"
			}, 500);
		}
	)
	$('.plus').click(function() {
		$('.child_nav:visible').slideUp().prev().removeClass('plus_on');
		var subnav = $(this).parents('li').find('.child_nav');
		// console.log(subnav.is(':hidden'))
		if(subnav.is(':hidden')) {
			subnav.slideDown().prev().addClass('plus_on');
		} else {
			subnav.slideUp().prev().removeClass('plus_on');
		};
	})

    if($(window).scrollTop()>0){
     $('.header,.moblie_header').addClass('mini');
    }


    $(window).scroll(function(e){
     var p=0,t=0;
        p = $(this).scrollTop();
        if(t <= p && p > 0){ //下滚
            $('.header,.moblie_header').addClass('mini');

        }else{ //上滚
           $('.header,.moblie_header').removeClass('mini');
        }
        setTimeout(function(){t = p;},0);
    });


  $(".float-right-box").each(function(){

		$(this).find(".f_WX").hover(function(){
			$(this).find('.sidebox').show();
		},function(){
			$(this).find('.sidebox').hide();
		});

		$(this).find(".btn_top").click(function(){
			$("body, html").stop().animate({
				"scrollTop": 0
	    	});
		});
	});






    // 点击大图
	$('.gallery').each(function() { // the containers for all your galleries
        $(this).magnificPopup({
            delegate: 'a', // the selector for gallery item
            type: 'image',
            gallery: {
                enabled: true
            }
        });
    });


	// 返回顶部
    $(window).scroll(function(){
    	var defaults = {
			showHeight : 800,
			speed : 1000
		};
		var scrolltop=$(this).scrollTop();
		if(scrolltop >= 1000){
			$(".to_top").show();
		}
		else{
			$(".to_top").hide();
		}
	});


 $(".to_top").click(function(){
		$("html,body").animate({scrollTop: 0}, 1000);
	});



    $(window).on('ajaxComplete', function() {
        setTimeout(function() {
            $(window).lazyLoadXT();
        }, 10);
    });




});
