import Vuex from "vuex"
import Vue from "vue";
import request from "@/common/request";

const state = {
}
const getters = {}

const mutations = {
}
const actions = {

}

Vue.use(Vuex)
export default new Vuex.Store({
    state,
    getters,
    mutations,
    actions
})