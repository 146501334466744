<script>
import {mapState} from "vuex";
import request from "@/common/request";

export default {
  name: "ProductTitles",
  data() {
    return {
      productList: []
    }
  },
  created() {
    this.getProductList();
  },
  methods: {
    getProductList() {
      request({
        url: "/web/product/getList",
        method: "get"
      }).then(response => {

        this.productList = response.data
      }).catch(error => {
        console.log(error);
      });
    }
  },
  mounted() {
    console.log(this.productList)
  }
}

</script>

<template>
  <div class="index_main">
    <div class="index_product">
      <div class="container">
        <ul class="oprodust_list">
          <li class="pro_item" v-for="product in productList" :key="product.id">
            <router-link :to="{
              path:'/productDetail',
              query:{
                id:product.id
              }
            }" :title="product.title" target='_self'>
              <div class="pro_img">
                <img :src="product.mainUrl"
                     :data-original="product.mainUrl" :alt="product.title"
                     class="pro_img" width="278px" height="278px">
              </div>
              <div class="note">
                <h3 class="nowrap"> {{ product.title }}</h3>
                <h2 class="nowrap"></h2>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style scoped>
@media (max-width:1023px) and (min-width:0px){
  .container{padding:0 3%;width:100%;box-sizing:border-box;}
  .header{display:none}
  .moblie_header{display:block;background:#fff;position:fixed;top:0px;left:50%;transform:translateX(-50%);-webkit-transform:translateX(-50%);-ms-transform:translateX(-50%);width:100%;z-index:9999;}
  .moblie_header .fl img{height:30px}
  .moblie_header .fr a{display:block;width:100%;height:100%;}
  .moblie_header .fr a img{height:18px;vertical-align:middle;margin-top:6px;}
  .menu_box{display:block;position:fixed;top:0px;left:0px;width:0px;height:0px;overflow:hidden;z-index:10000}
  .menu_box .menu_mark{position:absolute;width:100%;height:100%;background-color:rgba(0,0,0,0.5);left:0px;top:0px;z-index:1}
  .menu_box .btn_closed_box{position:absolute;width:20%;left:0px;top:5%;z-index:100;text-align:center;filter:alpha(opacity=0);opacity:0}
  .menu_box .menu_nav{position:absolute;width:80%;right:0px;top:0px;background:#ffffff;height:100%;z-index:100;right:-80%}
  .menu_box .menu_nav ul{padding-left:5%;padding-right:5%}
  .menu_box .menu_nav ul li{border-bottom:1px solid #f3f3f3;position:relative;}
  .menu_box .menu_nav ul li a{display:block;width:100%;height:45px;color:#333333;line-height:45px;padding-left:5%;font-size:16px;}
  .menu_box .menu_nav ul li.had_sonnav a{width:calc(100% - 45px);}
  .menu_box .menu_nav ul li .child_nav{display:none}
  .menu_box .menu_nav ul li .child_nav a{height:40px;line-height:40px;font-size:14px}
  .head_margin{height:50px;}
}


.oprodust_list {
  font-size: 0;
}


.pro_item {
  position: relative;
}

.pro_item .pro_img {
  width: 100%;
  display: block;
}

.pro_item {
  position: relative;
  overflow: hidden;
}

.pro_img {
  width: 100%;
  overflow: hidden;
}

.pro_img img {
  width: 100%;

  transition: all 0.5s ease-in-out;
}

.pro_item a:hover img {
  transform: scale(1.1);
}

.pro_item a {
  color: #333333;
}

.pro_item a:hover h3 {
  color: #ce1c28;
}

.pro_item .note {
  text-align: center;
  margin-top: 5px;
  line-height: 1.5;
}

.pro_item .note h3 {
  font-size: 16px;
  margin-bottom: 8px;
}

.pro_item .note h2 {
  font-size: 14px;
}

.pro_item:hover a {
  opacity: 1;
}

.index_product {
  margin-bottom: 50px;

}

@media (max-width: 767px) {
  .oprodust_list li {
    width: 48%;
  }

  .index_news {
    padding: 20px 0;
    width: 100%;
  }
}

@media (min-width: 767px) and (max-width: 1024px) {
  .oprodust_list li {
    width: 31.333%;
  }
}

</style>