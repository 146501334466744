<script>

import request from "@/common/request";

export default {
  name: "HomeBanner",
  data() {
    return {

      bannerList:[
      ],
      bannerHeight:""
    }
  },
  created() {
    this.getBanner()
  },
  methods:{
    getBanner(){
      request({
        url: "/web/banner/bannerList",
        method: "get"
      }).then(response => {

        this.bannerList = response.data
      }).catch(error => {
        console.log(error);
      });
    },
    imgLoad(){
      this.$nextTick(()=>{

        if (this.$refs.bannerImage != null){
          this.bannerHeight = this.$refs.bannerImage[0].height
        }

      })
    }
  },
  mounted() {
    this.imgLoad();
    window.addEventListener(
        "resize",
        () => {
          this.bannerHeight = this.$refs.bannerImage[0].height;
          this.imgLoad();
        },
        false
    );
  }
}
</script>
<template>
  <div class="banner_swiper">
    <el-carousel  :interval="5000" arrow="always" class="swiper" :height="bannerHeight + 'px'">
      <el-carousel-item v-for="banner in bannerList" :key="banner.id">
        <router-link :to="{
          path:'/productDetail',
          query:{
            id:banner.productId
          }
        }">
          <picture>
            <source media="(min-width: 768px)" :srcset="banner.url">
            <source media="(min-width: 0px)" :srcset="banner.url">
            <img :src="banner.bannerUrl" alt="banner" width="100%"  ref="bannerImage" @load="imgLoad">
          </picture>
        </router-link>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<style>


.banner_swiper {
  position: relative;
}

.mouse-down {
  position: absolute;
  width: 80px;
  height: 50px;
  line-height: 50px;
  z-index: 20;
  margin-top: 63px;
  text-align: center;
  cursor: pointer;
  left: 50%;
  bottom: -65px;
  margin-left: -40px;
  -webkit-animation-duration: 2s;
  animation-duration: 2s !important;
  animation-iteration-count: infinite;
}

.oprodust_list {
  font-size: 0;
}

.oprodust_list li {
  display: inline-block;
  vertical-align: top;
  width: 23%;
  margin: 1%;
  background-color: #ffffff;
}

.pro_item {
  position: relative;
}

.pro_item .pro_img {
  width: 100%;
  display: block;
}

.pro_item {
  position: relative;
  overflow: hidden;
}

.pro_img {
  width: 100%;
  overflow: hidden;
}

.pro_img img {
  width: 100%;
  transition: all 0.5s ease-in-out;
}

.pro_item a:hover img {
  transform: scale(1.1);
}

.pro_item a {
  color: #333333;
}

.pro_item a:hover h3 {
  color: #ce1c28;
}

.pro_item .note {
  text-align: center;
  margin-top: 5px;
  line-height: 1.5;
}

.pro_item .note h3 {
  font-size: 16px;
  margin-bottom: 8px;
}

.pro_item .note h2 {
  font-size: 14px;
}

.pro_item:hover a {
  opacity: 1;
}

.pro_sitem {
  height: 314px;
  margin-bottom: 4px;
}

.index_product {
  margin-bottom: 50px;
}

.index_news {
  padding: 50px 0;
  width: 100%;
}

@media (max-width: 767px) {
  .oprodust_list li {
    width: 48%;
  }

  .index_news {
    padding: 20px 0;
    width: 100%;
  }
}

@media (min-width: 767px) and (max-width: 1024px) {
  .oprodust_list li {
    width: 31.333%;
  }
}



</style>