import Vue from "vue";
import VueI18n from "vue-i18n";
import en from "@/language/en"
import zh from "@/language/zh"

Vue.use(VueI18n); // 全局挂载

export const i18n = new VueI18n({
    locale: localStorage.getItem("lang") || "zh", // 从localStorage中获取 默认英文
    messages: {
        zh: zh,
        en: en
    },
    fallbackLocale: 'zh'
});

export default i18n;