<script>
import HomeBanner from "@/components/home/HomeBanner.vue";
import ProductTitles from "@/components/home/ProductTitles.vue"
  export default {
    name:"HomePage",
    components:{HomeBanner,ProductTitles}
  }
</script>

<template>
  <div>
    <HomeBanner></HomeBanner>
    <ProductTitles></ProductTitles>
  </div>

</template>

<style scoped>

</style>