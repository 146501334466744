export default {
    msg:{
        top:{
            home:"Home",
            product:"Products",
            software:"Software",
            tutorial:"Tutorial",
            about:"About Us",
        },
        pageBanner:{
            product:"TECHNOLOGICAL INNOVATION, CLASSIC DESIGN",
            software:"SAFE AND STABLE, CONCISE AND EFFICIENT",
            tutorial:"LIMITLESS TECHNOLOGY, EXHAUSTIVE TUTORIALS",
            about:"UNIQUE INGENUITY, LEADING INDUSTRY",
        },
        software:{
            download:"Software download",
            update:"Software update"
        },
        about:{
            aboutUs:"About Us",
            contactUs:"Contact Us"
        },
        wechat:{
            scan:"Scan to follow",
            officialAccount:"Official Account"
        },
        tutorial:{
            all:"All",
            searchMsg:"Input keywords"
        },
        pageDetail:{
            part:"Related accessories",
            updateLog:"Update log",
            software:"Software download",
            productDetail:"Details",
            accessories:"Accessories",
            operations:"Operations",
            next:"Next",
            prev:"prev",
            period:"period"
        },
        foot:{
            companyName:"Hefei Yaokey Electronic Products Co., Ltd",
            address:"E428-E429, Intelligent Institute of Hefei University of Technology, Huayuan Avenue, Baohe District, Hefei City, Anhui Province",
            copyright:"yaokey Electron All rights reserved"
        },
        page: {
            home: "Home",
            prev:"prev",
            next:"next",
            lastPage:"Last Page"
        }


    }
}