<script>
  export default {
    name:"Box",
    methods:{
      scrollToTop(){
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      },
      handleMouseOver(){
        this.$refs.sidebox.style.display = 'block'
      },
      handleMouseLeave(){
        this.$refs.sidebox.style.display = 'none'
      }


    }
  }
</script>

<template>
  <div class="float-right-box show on hidden_xs">
    <div class="top-list">
      <ul>
        <li class="f_WX">
          <a class="f-cb weix"
             @mouseover="handleMouseOver"
             @mouseleave="handleMouseLeave">
            <span class="ico iconfont"></span>
            <p class="icon_name">{{$t("msg.wechat.officialAccount")}}</p>
            <div class="sidebox"
                 style="display: none;" ref="sidebox">
              <img src="@/assets/wechat.jpg" border="0"/>
              <p>{{$t("msg.wechat.scan")}}</p>
            </div>
          </a>
        </li>
        <li class="btn_top">
          <a class="f-cb return-webtop" @click="scrollToTop">
            <p class="ico iconfont"></p>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped>
.top-list{
  color: #202020;
}
</style>