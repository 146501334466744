import Vue from 'vue'
import App from './App.vue'
import VueRouter from "vue-router";
import store from "@/store";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from "@/router"
import {i18n} from "@/language"

import "@/assets/css/common.css"
import "@/assets/css/otherpage.css"
import "@/assets/css/reset.css"
import "@/assets/css/swiper.min.css"
Vue.config.productionTip = false
Vue.config.devtools = true

Vue.use(VueRouter)
Vue.use(ElementUI);

new Vue({
  render: h => h(App),
  store,
  router,
  i18n
}).$mount('#app')
