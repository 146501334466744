import VueRouter from "vue-router";

export default new VueRouter({
    routes:[
        {
            path:"*",
            redirect:"/home"
        },
        {
            path:"/home",
            component:() =>import("@/components/home/HomePage.vue")
        },
        {
            path:"/product",
            component:() =>import("@/components/product/ProductPage.vue")
        },
        {
            path:"/productDetail",
            component:() =>import("@/components/product/ProductDetailPage.vue")
        },
        {
            path:"/software",
            component:()=>import("@/components/software/SoftwarePage.vue"),
            children:[
                {
                    path:"/",
                    redirect:"/software/download"
                },
                {
                    name:"download",
                    path:"download",
                    component:() => import("@/components/software/SoftwareDownload.vue")
                },
                {
                    name:"update",
                    path:"update",
                    component:() => import("@/components/software/SoftwareUpdate.vue")
                },
                {
                    name: "record",
                    path: "record",
                    component: ()=>import("@/components/software/SoftwareRecord.vue")
                }
            ]
        },
        {
            path:"/tutorial",
            component:()=>import("@/components/tutorial/TutorialPage.vue")
        },
        {
            path:"/about",
            component:()=>import("@/components/about/AboutPage.vue"),
            children:[
                {
                    path: "/",
                    redirect: "/about/aboutUs"
                },
                {
                    name: "aboutUs",
                    path: "aboutUs",
                    component:()=>import("@/components/about/AboutUs.vue")
                },
                {
                    name: "contactUs",
                    path: "contactUs",
                    component:()=>import("@/components/about/ContactUs.vue")
                },
                {
                    name: "advice",
                    path: "advice",
                    component:() =>import("@/components/about/Advice.vue")
                }
            ]
        }
    ]
})