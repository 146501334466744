export default {
    msg: {
        top: {
            home: "网站首页",
            product: "产品中心",
            software: "软件服务",
            tutorial: "技术教程",
            about: "关于我们",
        },
        pageBanner: {
            product: "科技创新，经典设计",
            software: "安全稳定，简洁高效",
            tutorial: "技术无限，教程详尽",
            about: "独具匠心，行业领先",
        },
        software: {
            download: "软件下载",
            update: "软件更新"
        },
        about: {
            aboutUs: "关于我们",
            contactUs: "联系我们"
        },
        wechat: {
            scan: "扫码关注",
            officialAccount: "公众号"
        },
        tutorial: {
            all: "全部",
            searchMsg: "请输入关键词"
        },
        pageDetail: {
            part: "相关配件",
            updateLog: "更新日志",
            software: "软件下载",
            productDetail: "产品详情",
            accessories: "配件介绍",
            operations: "操作概览",
            next: "下一个",
            prev: "上一个",
            period: "没有了"
        },
        foot: {
            companyName: "合肥市耀匙电子产品有限公司",
            address: "安徽省合肥市包河区花园大道合肥工业大学智能院E428-E429",
            copyright: "耀匙电子 版权所有"
        },
        page: {
            home: "首页",
            prev:"上一页",
            next:"下一页",
            lastPage:"尾页"
        }


    }
}