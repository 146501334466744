var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{class:_vm.classObj,attrs:{"id":"head"}},[_c('div',{staticClass:"container_wrap clearfix"},[_c('div',{staticClass:"logo fl",staticStyle:{"width":"145px","left":"5%"}},[_c('img',{staticClass:"index_logo",attrs:{"src":require("@/assets/logo.png"),"alt":"合肥市耀匙电子产品有限公司","title":"合肥市耀匙电子产品有限公司"},on:{"click":function($event){return _vm.$router.go(0)}}})]),_c('div',{staticClass:"nav fr nav_bar"},[_c('ul',{staticClass:"nav_pc clearfix yellow"},[_c('li',{class:{active :_vm.getActiveResult('home')}},[_c('router-link',{attrs:{"to":"/home"}},[_vm._v(_vm._s(_vm.$t("msg.top.home")))])],1),_c('li',{class:{active :_vm.getActiveResult('product')}},[_c('router-link',{attrs:{"to":"/product"}},[_vm._v(_vm._s(_vm.$t("msg.top.product")))])],1),_c('li',{class:{active : _vm.getActiveResult('software')}},[_c('router-link',{attrs:{"to":{
            path:'/software',
            query:{
              isShow:true
            }
          }}},[_vm._v(_vm._s(_vm.$t("msg.top.software"))+" ")])],1),_c('li',{class:{active : _vm.getActiveResult('tutorial')}},[_c('router-link',{attrs:{"to":"/tutorial"}},[_vm._v(_vm._s(_vm.$t("msg.top.tutorial")))])],1),_c('li',{class:{active : _vm.getActiveResult('about')}},[_c('router-link',{attrs:{"to":"/about"}},[_vm._v(_vm._s(_vm.$t("msg.top.about")))])],1),_c('div',{staticClass:"top_language"},[_c('a',{class:{active:this.$i18n.locale === 'zh'},on:{"click":function($event){$event.preventDefault();return _vm.translate('zh',true)}}},[_vm._v("中文")]),_c('a',{class:{active:this.$i18n.locale === 'en'},on:{"click":function($event){$event.preventDefault();return _vm.translate('en',false)}}},[_vm._v("EN")])])])])])]),_c('div',{staticClass:"moblie_header"},[_c('div',{staticClass:"container clearfix"},[_c('div',{staticClass:"fl"},[_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.$router.go(0)}}},[_c('img',{attrs:{"src":require("@/assets/logo.png"),"alt":"合肥市耀匙电子产品有限公司","title":"合肥市耀匙电子产品有限公司"}})])]),_c('div',{staticClass:"fr"},[_c('a',{staticClass:"menu",on:{"prevent":function($event){}}},[_c('img',{attrs:{"alt":"","src":require("@/assets/menu.png")}})])])])]),_c('div',{staticClass:"menu_box"},[_c('div',{staticClass:"menu_mark"}),_c('div',{staticClass:"btn_closed_box"},[_c('a',{staticClass:"btn_closed",on:{"click":function($event){$event.preventDefault();}}},[_c('img',{attrs:{"src":require("@/assets/close.png"),"width":"22","alt":""}})])]),_c('div',{staticClass:"menu_nav"},[_c('ul',[_c('li',{staticClass:"had_sonnav"},[_c('router-link',{attrs:{"to":"/home"}},[_vm._v(_vm._s(_vm.$t("msg.top.home")))])],1),_c('li',{staticClass:"had_sonnav"},[_c('router-link',{attrs:{"to":"/product"}},[_vm._v(_vm._s(_vm.$t("msg.top.product")))])],1),_c('li',{staticClass:"had_sonnav"},[_c('router-link',{attrs:{"to":{
            path:'/software',
            query:{
              isShow:true
            }
          }}},[_vm._v(_vm._s(_vm.$t("msg.top.software"))+" ")])],1),_c('li',{staticClass:"had_sonnav"},[_c('router-link',{attrs:{"to":"/tutorial"}},[_vm._v(_vm._s(_vm.$t("msg.top.tutorial")))])],1),_c('li',{staticClass:"had_sonnav"},[_c('router-link',{attrs:{"to":"/about"}},[_vm._v(_vm._s(_vm.$t("msg.top.about")))])],1),_c('li',{staticClass:"had_sonnav"},[_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.translate('zh',true)}}},[_vm._v("中文")])]),_c('li',{staticClass:"had_sonnav"},[_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.translate('en',false)}}},[_vm._v("EN")])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }